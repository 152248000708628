button:focus,
button:focus-visible,
button:focus-within,
button:active {
    outline: none;
    box-shadow: unset;
}
.p-sidebar-mask {
    z-index: 9999 !important;
}
.personal_template .main-title-text {
    @apply 3xl:text-[48px] 2xl:text-[36px] xl:text-[32px] md:text-[26px] tracking-[-0.05em] font-normal text-[24px];
}
.shadow-InputAndButton {
    --tw-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    --tw-shadow-colored: 0px 1px 2px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.form_input_control {
    @apply text-sm py-[7px] leading-[20px] rounded-lg placeholder:text-xs placeholder:leading-[18px] border border-borderCounter px-0 focus:ring-0 focus:border-headerBorderColor focus:ring-offset-0 focus-visible:outline-none w-full shadow-[0px_1px_2px_0px_#1018280D];
}

.form_input_control.form_password_control {
    @apply pe-14;
}
.btn_primary {
    @apply form_input_control bg-primaryAppointment text-txtAppointmentColor text-base h-[46px] font-semibold  rounded-lg border border-primaryAppointment transition-all ease-in-out shadow-InputAndButton;
}
.login-title-text {
    @apply text-xl md:text-[28px] -tracking-[0.04rem] text-txtAppointmentColor leading-6 md:leading-[33.6px] font-semibold mb-2;
}
.login-subtitle-text {
    @apply text-textGrey text-base font-normal -tracking-[0.03rem];
}
.bg-backdrop {
    background: rgba(0, 0, 0, 0.3);
    overscroll-behavior: contain;
}
.sidebar-shadow {
    /* box-shadow: 0px 4px 24px 10px #B4B4B412; */
    /* box-shadow: 0 0px 16px 1px rgba(0,0,0,0.10); */
    box-shadow: 0px 4px 20px 10px rgba(180, 180, 180, 0.1);
}
.p-sidebar-header {
    @apply px-6 pt-4;
}
.p-sidebar-content {
    @apply px-5 flex flex-col flex-1;
}
.fl-tab-btn-view {
    @apply flex gap-3  items-center;
}
.fl-tab-btn-view .fl-tab-link {
    @apply rounded-secondary text-sm font-normal border h-[28px] px-1.5  lg:px-2.5 py-1 items-center justify-center flex;
}
.fl-tab-btn-view .fl-tab-link.active {
    @apply bg-cardBackground text-primaryAppointment border rounded-secondary border-primaryAppointment;
}
.fl-tab-btn-view .fl-tab-link:hover {
    @apply bg-cardBackground text-primaryAppointment border rounded-secondary border-primaryAppointment;
}
.shadow-custom {
    box-shadow: 0px 0px 8px 2px rgba(170, 170, 170, 0.15);
}
.search-box .form_input_control {
    height: 44px !important;
}
.search-box .form_input_control:focus,
.search-box .form_input_control:hover,
.search-box .form_input_control:active,
.search-box .form_input_control:focus-visible,
.search-box .form_input_control:focus-within {
    --tw-border-opacity: 1;
    --tw-shadow: 0 1px 2px 0px #1018280d, 0 0px 0px 4px var(--box-shadow-color) !important;
    --tw-shadow-colored: 0 1px 2px 0px var(--tw-shadow-color), 0 0px 0px 4px var(--tw-shadow-color) !important;
    border-color: rgb(199 215 254 / var(--tw-border-opacity));
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
    border-color: var(--border-counter);
}

.form_input_otp:focus,
.form_input_otp:hover,
.form_input_otp:active,
.form_input_otp:focus-visible,
.form_input_otp:focus-within {
    --tw-border-opacity: 1;
    --tw-shadow: 0 1px 2px 0px #1018280d, 0 0px 0px 4px var(--box-shadow-color) !important;
    --tw-shadow-colored: 0 1px 2px 0px var(--tw-shadow-color), 0 0px 0px 4px var(--tw-shadow-color) !important;
    /* border-color: rgb(199 215 254 / var(--tw-border-opacity)) !important; */
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
    border-color: transparent !important;
    --tw-ring-color: var(--brand-color) !important;
}

.select_box_main .select__control {
    padding: 1px 2px 0 10px;
    height: 40px;
    min-width: 170px;
    color: #101828;
    font-weight: 600;
    -webkit-appearance: none !important;
    appearance: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    width: 100%;
    border-radius: var(--brand-border-radius-secoundary);
    border: 1px solid var(--template-card-border-color);
    --tw-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    --tw-shadow-colored: 0px 1px 2px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    background: transparent;
    background-color: transparent;
}
.navigation-select .select__control {
    min-width: 57px;
    height: 30px;
    min-height: 30px;
    max-height: 30px;
    border: 1px solid var(--border-color-primary) !important;
    cursor: pointer;
    box-shadow: none;
    padding-left: 8px;
    padding-right: 8px;
}
.navigation-select .right-icon-wrapper {
    width: 9px;
}
.navigation-select .select__control .select__indicators .select__indicator {
    padding: 0 0px 0 0 !important;
    width: 14px;
}
.navigation-select .select__indicator {
    color: var(--main-text-color);
}
.navigation-select .select_box_main .select__control .select__indicators .select__indicator {
    padding: 0;
}
.navigation-select .select__menu {
    min-width: 57px !important;
}
.navigation-select .navigation-select .select__option {
    padding: 5px 11px !important;
}
.navigation-select .select__control:focus {
    border: 0px solid var(--border-color-primary);
}
.navigation-select .select__control:focus-visible {
    border: 0px solid var(--border-color-primary);
}
.navigation-select .select__control:focus-within {
    border: 0px solid var(--border-color-primary);
}
.navigation-select .select__control:hover {
    border: 0px solid var(--border-color-primary);
}
.select_box_main .select__control:focus {
    border: 1px solid var(--border-color-primary);
}
.select_box_main .select__control:focus-visible {
    border: 1px solid var(--border-color-primary);
}
.select_box_main .select__control:focus-within {
    border: 1px solid var(--border-color-primary);
}
.select_box_main .select__control:hover {
    border: 1px solid var(--border-color-primary);
}
.select_box_main .select__control .select__placeholder {
    color: var(--main-text-color);
    font-size: 14px;
    font-weight: 400;
}
.select_box_main .select__control .select__single-value {
    font-size: 14px !important;
    font-weight: 400;
}
.navigation-select .select__control .select__single-value {
    font-size: 14px !important;
    font-weight: 400;
    margin-top: -1px !important;
    line-height: 28px;
}
.select_box_main .select__control .select__indicators .select__indicator {
    padding: 4px;
}
.select_box_main .select__control .select__indicators .select__indicator-separator {
    display: none;
}
.select_box_main .select__menu {
    min-width: 200px;
    position: absolute;
    right: 0px;
    z-index: 10;
    border-radius: 8px;
}
.select_box_main .select__input-container {
    margin-top: 3px;
}
.select_box_main .select__indicator {
    margin-top: -1px;
}
.select_box_main .select__indicator-separator {
    margin-left: 5px;
}
.select_box_main .select__menu-list {
    padding: 0px;
    background-color: var(--dropdown-background-color);
}
.select_box_main .select__option--is-selected {
    background-color: var(--card-background-color) !important;
    color: var(--main-text-color) !important;
}
.select_box_main .select__option {
    background-color: var(--card-background-color) !important;
    color: var(--main-text-color) !important;
    font-size: 14px;
}
.select_box_main .checkicon {
    display: none;
}
.select_box_main .select__option--is-selected .checkicon {
    display: block;
}
.modal_backdrop {
    z-index: 99999 !important;
    margin: 0px !important;
}
.md\:after\:rounded-2xl::after {
    content: var(--tw-content);
    border-radius: var(--brand-border-radius) !important;
}

.after\:border-primary::after {
    border-color: var(--brand-color) !important;
}

/* .md\:after\:w-\[58px\]::after {
      width: 62px !important;
    }

    .md\:after\:h-\[58px\]::after {
      height: 62px !important;
    } */

.fl-input-field.fl-phone-field .fl-flag:focus {
    border-width: 0px !important;
    border-color: transparent !important;
}

.fl-input-field.fl-phone-field .fl-flag:active {
    border-width: 0px !important;
    border-color: transparent !important;
}

.button-active {
    background-color: var(--brand-color) !important;
    color: var(--brand-text-color) !important;
}

#flowbite-dropdown {
    right: 0% !important;
}

.variant {
    @apply flex flex-col items-center py-[1px] px-[4px] border border-borderColorPrimary rounded-[6px] text-textGrey;
}

.variant:hover {
    @apply border-primary;
}

.variant .variant_text_title {
    @apply border-borderColorPrimary text-xs font-medium -tracking-[0.01rem] leading-[150%];
}

.variant .variant_text_price {
    @apply text-xs font-medium -tracking-[0.01rem] leading-[150%];
}
.variant.active_add {
    @apply text-primaryAppointment border border-primaryAppointment;
}

.variant_border {
    @apply border border-borderColorPrimary h-[1px] w-[90%] mx-auto group-hover:border-primary;
}
.variant_border.active_add {
    @apply text-primaryAppointment border border-primaryAppointment;
}
.login-model-title {
    @apply leading-[28px] text-txtcolor text-[21px] lg:text-[24px] text-center lg:leading-[28px] font-bold lg:font-bold mb-2 flex justify-center -tracking-[0.01rem]  first-letter:uppercase;
}
.login-model-title-mobile {
    @apply first-letter:uppercase text-[16px] -tracking-[0.01rem] font-bold leading-[22.4px] text-txtAppointmentColor;
}
.login-model-title::first-letter {
    text-transform: uppercase !important;
}
.login-model-subtitle {
    @apply text-textGrey leading-[22.4px] font-normal text-[16px] -tracking-[0.02rem] flex justify-center text-center;
}
.login-model-subtitle-dec {
    @apply text-txtcolor leading-[22.4px] mt-0.5 font-semibold lg:font-bold text-[16px] -tracking-[0.02rem] flex justify-center text-center;
}
.login-model-description {
    @apply text-textGrey leading-[22.4px] font-normal text-[16px] -tracking-[0.02rem] flex justify-center text-center;
}
.asterisk {
    @apply text-primary text-xs font-medium ms-[2px];
}
.model-header-btn {
    @apply absolute right-[12px] top-[12px] !p-0 text-textGrey;
}
.model-inner-wrapper {
    @apply min-w-full sms:min-w-[360px] md:min-w-[360px] max-w-[360px] mx-auto pt-[16px] pb-[20px] md:pt-[50px] md:pb-[50px];
}
.mobile-model-inner-wrapper {
    @apply pt-[16px];
}
.login-footer {
    @apply bg-footerPolicyBackgroundColor border-borderColorPrimary w-full flex p-4 text-center justify-center items-center;
}
.mobile-model-inner {
    @apply px-4 max-w-[432px] mx-auto;
}
.outline-custom {
    @apply outline-none shadow-none focus:outline-none focus:shadow-none focus-visible:outline-none focus-visible:shadow-none focus-within:outline-none focus-within:shadow-none;
}
.upload-pic-input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    cursor: pointer;
    left: 0;
}
.upload-pic-label svg path {
    stroke: #85888e;
}
.useAppModel .model-header-btn button {
    height: 24px;
    width: 24px;
    right: 0px;
    position: relative;
    top: 10px;
    padding: 0;
    color: var(--tooltip-bg);
}
.shadow-default {
    box-shadow: 0px 8px 14px -4px rgba(16, 24, 40, 0.06);
}
.shadow-custom-sm {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.shadow-custom-md {
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}
.shadow-custom-lg {
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}
.location-hours:hover {
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}
/* .testimonialSlider .swiper-wrapper
{
    flex-direction: column !important;
    gap: 16px;
}
.testimonialSlider .swiper-slide
{
    width: 100% !important;
} */
