.loader {
    width: 100%;
    background-color: white;
    top: 50%;
    left: 0;
    right: 0;
    position: absolute;
    border-radius: 100px;
    // box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.05);
}

.loader:after {
    border-radius: 100px;
    content: "";
    position: absolute;
    background-color: #ddd;
    left: 2px;
    top: 2px;
    height: 4px;
    bottom: 2px;
    right: 95%;
    animation-name: slide;
    animation-duration: 3s;
    animation-easing-function: linear;
    animation-iteration-count: infinite;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

@keyframes slide {
    0% {
        right: 95%;
        left: 2px;
    }
    50% {
        right: 2px;
        left: 95%;
    }
    100% {
        right: 95%;
        left: 2px;
    }
}