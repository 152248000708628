@font-face {
    font-family: "mulish";
    src: url('./Mulish-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "mulish";
    src: url('./Mulish-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "mulish";
    src: url('./Mulish-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "mulish";
    src: url('./Mulish-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "mulish";
    src: url('./Mulish-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "mulish";
    src: url('./Mulish-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "mulish";
    src: url('./Mulish-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "mulish";
    src: url('./Mulish-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}