.mobile_view-datepicker {
    width: 100%;
    box-shadow: none;
    top: 0;
    position: relative;
    z-index: 10;
    .react-datepicker__current-month,
    .react-datepicker__navigation {
        display: none;
    }
    .react-datepicker__month {
        margin: 0;
        &-container {
    width: 100%;
        }
    }
    .dot_show {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            width: 4px;
            height: 4px;
            border-radius: 10px;
            background-color: #1642c5;
            bottom: 2px;
            text-align: center;
            left: 45%;
            transform: translateY(-45%);
        }
    }
    .react-datepicker__week {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .react-datepicker__day {
        padding: 8px;
        width: 2.5rem;
        line-height: 150%;
        letter-spacing: -0.12px;
        &--selected {
            &::after {
                background-color: #fff;
            }
        }
        &--disabled{
            color: #87888D;
        }
        &-names {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
            .react-datepicker__day-name {
                padding: 1px 0.75rem;
                line-height: 150%;
                width: unset;
                color: #87888D;
            }
        }
    }
    .react-datepicker__header {
        padding: 0;
        .react-datepicker__header__dropdown {
            text-align: left;
            margin-bottom: 20px;
        }
    }
    .react-datepicker__month-dropdown-container {
        margin: 0 12px;
    }
    .react-datepicker__month-read-view {
        color: #1a1d25;
        font-size: 16px;
        font-weight: bold;
        line-height: 140%;
        letter-spacing: -0.16px;
    }
    .react-datepicker__month-read-view--down-arrow {
        top: 4px;
    }
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__navigation-icon::before {
        border-width: 2px 2px 0 0;
        border-color: #1a1d25;
        border-radius: 2px;
    }
    .react-datepicker__week{
        margin-bottom: 10px;
    }
    .react-datepicker__month-dropdown {
        left: 0;
        background-color: #fff;
        box-shadow: 0px 5.333px 16px 0px rgba(146, 140, 151, 0.25);
        border: none;
        border-radius: 24px;
        color: #8c9cbb;
        font-size: 12px;
        font-weight: 500;
        padding: 0.5rem 0;
        .react-datepicker__month-option {
            padding: 0.2rem;
            &--selected_month {
                background-color: #1642c5;
                color: #fff;
            }
        }
    }
}
