.mapboxgl-popup{
    @apply !max-w-[200px] ;
}
.mapboxgl-popup .mapboxgl-popup-content{
  @apply p-0 rounded-xl overflow-hidden shadow-[0px_5px_33px_0px_#00000014] bg-cardBackground;
}
.mapboxgl-canvas
{
  height: 100% !important;
}
 /* .mapboxgl-popup-tip {
  border-bottom-color: transparent !important;
  border-top-color: transparent !important;
} */