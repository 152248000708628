@import "../../../node_modules/react-datepicker/src/stylesheets/datepicker.scss";
.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker {
    z-index: 2;
    border: 1px solid #eaecf0 !important;
    background: #fff;
    position: absolute !important;
    right: 0 !important;
    top: 80px !important;
    z-index: 30;

    .react-datepicker__triangle {
        &::after {
            content: none !important;
        }
        &::before {
            content: none !important;
        }
    }
    .react-datepicker__input-container {
        width: 100%;
    }
    .react-datepicker__header {
        background-color: transparent;
        padding: 0;
        &:not(.react-datepicker__header--has-time-select) {
            border-bottom: none;
        }
    }
    .react-datepicker__current {
        &-month {
            margin: 1.5rem 0;
        }
    }
    .react-datepicker__month-select,
    .react-datepicker__year-select {
        font-size: 14px;
        line-height: 1;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        cursor: pointer;
        padding-left: 0.25rem;
        padding-right: 1.75rem;
        padding-top: 8px;
        padding-bottom: 8px;
        border-color: #e0e0e0;
        border: 0;
        background-size: 0.65em 0.65em;
        &:focus{
            border: 0;
            outline: 0;
            box-shadow: unset;
        }
    }
    .react-datepicker__navigation {
        top: 18px;
        border: 0 solid var(--brand-color);
        height: 38px;
        width: 38px;
        margin: 0 1rem;
        border-radius: var(--brand-border-radius);
        .react-datepicker__navigation-icon {
            &--next {
                left: 0;
                top: 0;
            }
            &--previous {
                left: 26px;
                &::before {
                    right: 20px;
                }
            }
            &::before {
                border-width: 2px 2px 0px 0;
                border-color: #8c9cbb;
                border-radius: 1px;
                // top: 10px;
            }
        }
    }
    .react-datepicker__day-names {
        .react-datepicker__day-name {
            color: #8c9cbb;
            font-size: 12px;
            width: 2.75rem;
            margin: 0;
            padding: 0.5rem 0.75rem;
            line-height: 150%;
        }
    }
    .react-datepicker__day {
        font-size: 12px;
        width: 2.75rem;
        margin: 0;
        padding: 0.5rem 0.75rem;
        &--keyboard-selected {
            background-color: #1642c5;
            color: #fff;
        }
        &--selected {
            background-color: #1642c5;
            color: #fff;
            border-radius: 8px;
            @media (min-width: 768px) {
                border-radius: 12px;
            }
        }
    }
    .react-datepicker__month {
        margin: 1rem;
    }
}
.profile-view-datepicker {
    top: -100px;
    right: unset;
    .react-datepicker__current {
        &-month {
            text-align: left;
            margin: 1.5rem 0 1.5rem 75px;
        }
    }
    .react-datepicker__header__dropdown {
        position: absolute;
        top: 0px;
        right: 18%;
    }
}


.react-datepicker {
    border: 1px solid #eaecf0;
    z-index: 2;
    border-radius: 8px;
    background: var(--white);
    color: var(--mainTextColor) !important;
}
.react-datepicker-popper {
    z-index: 1;
    transform: translate(0px, 33px) !important;
    width: 100%;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name
{
    font-size: 12px;
    font-weight: 400;
    color: #344054;
}
.react-datepicker--time-only {
    /* min-width: 130px; */
    width: 100% !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    border-radius: 12px !important;
    background: var(--white) !important;
    /* overflow: hidden; */
}
.react-datepicker__time-container {
    width: 100% !important;
}
.react-datepicker__header--time--only {
    display: none !important;
}

.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time {
    background: var(--white) !important;
    padding: 6px;
    
}
.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    margin: 0;
    width: 100%;
    padding: 0 0;
}
.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list {
    padding: 0;
    height: 100%;
    max-height: 185px;
    border-radius: 12px !important;
    scrollbar-width: none !important;
}
.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item {
    text-align: left;
    margin: 2px 0;
    padding-right: 22px;
}
.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item:hover {
    color: var(--primary);
    font-weight: normal;
    background: #f9fafb;
    border-radius: 6px;
    font-weight: 500;
}

.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected:hover,
.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected {
    color: var(--primary);
    background-color: #f9fafb !important ;
    font-weight: 500;
    background-size: 24px 24px;
    border-radius: 6px;
    padding-left: 8px;
    padding-right: 8px;
}

.react-datepicker .react-datepicker__triangle::after {
    content: none !important;
}
.react-datepicker .react-datepicker__triangle::before {
    content: none !important;
}
.react-datepicker .react-datepicker__input-container {
    width: 100%;
}
.react-datepicker .react-datepicker__header {
    background-color: transparent;
}
.react-datepicker .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-bottom: none;
}
.react-datepicker .react-datepicker__header .react-datepicker__current-month {
    margin: 16px 0 12px;
    color: #344054;
    font-family: var(--brand-font-family);
    font-size: 14px;
    font-weight: 600;
}
.react-datepicker .react-datepicker__header
{
    padding: 0px;
}
.react-datepicker .react-datepicker__navigation {
    top: 14px;
    border: 0px solid #f1f1f1;
    height: auto;
    width: 38px;
    margin: 0 1rem;
    border-radius: 8px;
    text-indent: unset;
}
.react-datepicker .react-datepicker__navigation .react-datepicker__navigation-icon {
    position: relative;
    text-indent: -9999px;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: #101828;
    justify-content: center;
}
.react-datepicker .react-datepicker__navigation .react-datepicker__navigation-icon::before {
    left: unset;
    top: unset;
    right: unset;
    bottom: unset;
    border-width: 2px 2px 0px 0;
    border-color:#eaecf0;
    border-radius: 1px;
    margin-top: -2px;
}
.react-datepicker .react-datepicker__navigation .react-datepicker__navigation-icon--next::before {
    margin-left: -3px;
}
.react-datepicker .react-datepicker__day-names .react-datepicker__day-name {
    color: #344054;
    font-size: 12px;
    width: 35px;
    height: 35px;
    margin: 0;
    padding: 0.5rem;
}
.react-datepicker .react-datepicker__day {
    font-size: 12px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 0;
    padding: 0.25rem;
}
.react-datepicker .react-datepicker__day--keyboard-selected {
    background-color: #3570f8;
    color: var(--white);
    position: relative;
}
.react-datepicker .react-datepicker__day--keyboard-selected:before {
    content: '';
    height: 5px;
    width: 5px;
    background-color: var(--white);
    border-radius: 50%;
    position: absolute;
    top: 77%;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
}
.react-datepicker .react-datepicker__day--selected {
    background-color: #3570f8;
    color: var(--white);
    position: relative;
}
.react-datepicker__week
{
    margin-bottom: 4px;
}
.react-datepicker .react-datepicker__day--selected:before {
        content: '';
        height: 5px;
        width: 5px;
        background-color: var(--white);
        border-radius: 50%;
        position: absolute;
        top: 77%;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%);
    }
.react-datepicker .react-datepicker__month {
    margin: 1rem;
}

.react-datepicker .react-datepicker__day--outside-month {
    color: #bec9dd;
}

.react-datepicker .react-datepicker__day--in-range {
    background-color: #f2f4f7;
    border-radius: 0;
    color: #344054;
}
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--keyboard-selected {
    background-color: #f2f4f7;
}
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--selected, .react-datepicker .react-datepicker__day--in-range.react-datepicker__day--range-end {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
    position: relative;
    z-index: 2;
    color: #fff;
}
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--selected:after, .react-datepicker .react-datepicker__day--in-range.react-datepicker__day--range-end:after {
    content: '';
    position: absolute;
    background-color: #3570f8;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 50%;
}
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--range-end.react-datepicker__day--selected {
    border-radius: 50%;
}
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--range-end {
    border-radius: 0;
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
}
.date_of_birth .react-datepicker {
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    top: -13px !important;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    right: 0;
    left: 0 !important;
    width: fit-content !important;
}