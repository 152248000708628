.personal_template .main-title-text
{
    @apply 3xl:text-[48px] 2xl:text-[36px] xl:text-[32px] md:text-[26px] tracking-[-0.05em] font-normal text-[24px];
}
.pt-side-spaching
{
    @apply px-5 lg:px-8;
}
.pt-spaching-top
{
    @apply pt-[45px] md:pt-[65px] lg:pt-[65px] xl:pt-[80px] 2xl:pt-[90px] 3xl:pt-[120px];
}
.pt-spaching-bottom
{
    @apply pb-[45px] md:pb-[65px] lg:pb-[65px] xl:pb-[80px] 2xl:pb-[90px] 3xl:pb-[120px];
}
.btn-personal-primary
{
    @apply min-w-[120px] bg-primaryAppointment text-white;
}
.footer-title-text
{
    @apply first-letter:capitalize text-secondaryTxt font-medium text-[14px] lg:text-[16px] mb-3;
}
.footer-text
{
    @apply text-txtcolor text-[14px] lg:text-[16px] font-medium;
}
.header-title:nth-child(2)
{
    @apply opacity-50;
}