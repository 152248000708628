@font-face {
    font-family: "inter";
    src: url('Inter-Light.woff2') format('woff2'),
        url('Inter-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "inter";
    src: url('Inter-Regular.woff2') format('woff2'),
        url('Inter-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "inter";
    src: url('Inter-Medium.woff2') format('woff2'),
        url('Inter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "inter";
    src: url('Inter-SemiBold.woff2') format('woff2'),
        url('Inter-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "inter";
    src: url('Inter-Bold.woff2') format('woff2'),
        url('Inter-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
